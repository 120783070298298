.photo-formateur {
    height: 180px;
    width: 180px;
}

.center-img {
    text-align: center;
}

.photo-formateur {
    width : auto;
    height : auto;
    max-width : 200px;
    max-height : 150px;
}

.formateur-card {
    height: 500px;
    width: 300px;
}

.formateur-min-height {
    min-height: 20rem;
}