
.articles {

    .article-img {
        cursor: pointer;
    }
    
    .card {
        a:hover, a:visited, a:link, a:active {
            cursor: pointer;
            text-decoration: none;
            color: black; 
        
        }
        &:hover {
            background-color: aliceblue;
        }
    }

}