html, body {
    // background: no-repeat;
    // background-color: rgba(1, 1, 146, 0.25);
    // background-image: url('./dive-wallpaper.jpg');
    height: 100%;
}

#root {
    min-height: 100%;
    padding-bottom: 18rem;
    position:relative;

    
    @media screen and (max-width: 767px) {
        padding-bottom: 23rem;
    }
}

:not(nav)>.container {
    margin-top: 3rem;
}