
.carousel-control-next, .carousel-control-prev {
    width: 10%;

    > span {
        width: 3rem;
        height: 4rem;
        background-color: rgb(0, 0, 0, .9);
        // border-radius: .5rem;
    }
}

.carousel-control-next > span {
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
}

.carousel-control-prev > span {
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
}

h2 {
    margin-bottom: 1.5rem;
}

h3 {
    margin-bottom: 1.25rem;
}

h4 {
    margin-bottom: 1rem;
}