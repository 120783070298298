*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

$primary: hsl(222, 50%, 45%);
$danger: hsl(0, 50%, 45%);

@import "~bootstrap/scss/bootstrap";