.carriere-container {

    .carousel .slide img {
        max-height: 50rem;
        width: auto;
    }

    .photo-dimension {
        height: 60rem;
        width: auto;
    }
}

