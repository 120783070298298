.background-footer {

    background: hsl(222, 50%, 45%);

    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 15rem;

    color: white;

    a {
        color: white;
        text-decoration:none
    }

    a:hover {
        color: aliceblue;
        text-decoration: underline;
    }

    .contact-footer > * {
        margin-right: 1rem;
    }

    .inline { 
        display: inline; 
    } 

    .border-left { 
        padding-left: 1rem; 
        margin-left: 1rem; 
        border-left: 2px solid #FFFF; 
    }

    @media screen and (max-width: 767px) {
        .second-lvl-sm {
            margin-top: 1.5rem;
        }
    }
}